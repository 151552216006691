import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { Button, Descriptions, type DescriptionsProps, Divider, Flex, Spin, Table, Tabs, Typography } from 'antd'

import { ProductService } from '../../../services/ProductService'
import { EditProductModal } from '../../components/EditProductModal'
import type { Bundle, Product } from '../../../types'
import { AddBundleModal } from '../../components/AddBundleModal'
import { ColumnType } from 'antd/es/table'
import { useConfirmation } from '@store/confirmation'

export const ProductDetailsPage = () => {
    const [product, setProduct] = useState<Product>()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isAddBundleModalOpen, setIsAddBundleModalOpen] = useState(false)

    const { id } = useParams()
    const confirm = useConfirmation()

    const fetch = async () => {
        try {
            const results = await ProductService.get(id!)
            setProduct(results)
        } catch (err) {
            console.error('Error retrieving product information', err)
        }
    }

    const removeBundle = async (bundleId: string) => {
        if (!product) return

        try {
            if (await confirm({ title: 'Are you sure you want to remove this bundle?' })) {
                await ProductService.removeBundle(product.id, bundleId)
                await fetch()
            }
        } catch (err) {
            console.error('Error removing bundle', err)
        }
    }

    useEffect(() => {
        if (id) {
            fetch()
        }
    }, [id])

    const closeEditModal = () => {
        setIsEditModalOpen(false)
    }

    const items: DescriptionsProps['items'] = [
        {
            key: '0',
            label: 'ID',
            children: product?.id
        },
        {
            key: '1',
            label: 'Name',
            children: product?.name
        },
        {
            key: '2',
            label: 'Label',
            children: product?.label
        },
        {
            key: '3',
            label: 'Key',
            children: product?.key
        },
        {
            key: '4',
            label: 'Description',
            children: product?.description
        },
        {
            key: '5',
            label: 'Created At',
            children: product?.createdAt ? dayjs(product?.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        },
        {
            key: '6',
            label: 'Updated At',
            children: product?.updatedAt ? dayjs(product?.updatedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
        }
    ]

    if (!product) {
        return <Spin />
    }

    return (
        <Flex gap="middle" vertical>
            <Typography.Title>{product?.name}</Typography.Title>

            <Tabs>
                <Tabs.TabPane tab="Product Info" key="info">
                    <Descriptions
                        title="Product Info"
                        items={items}
                        column={3}
                        extra={<Button onClick={() => setIsEditModalOpen(true)}>Edit</Button>}
                    />
                    <Divider />
                    <Flex justify="space-between" align="center">
                        <Typography.Title level={3}>Bundles</Typography.Title>
                        <Button type="primary" onClick={() => setIsAddBundleModalOpen(true)}>
                            + Add
                        </Button>
                    </Flex>
                    <Table
                        columns={
                            [
                                {
                                    title: 'Name',
                                    dataIndex: 'name'
                                },
                                {
                                    title: 'Actions',
                                    render: (_, record) => (
                                        <Button type="link" onClick={() => removeBundle(record.id)}>
                                            Remove
                                        </Button>
                                    )
                                }
                            ] as ColumnType<Bundle>[]
                        }
                        dataSource={product.productBundles?.map(pb => pb.bundle) ?? []}
                    />
                </Tabs.TabPane>
            </Tabs>
            <EditProductModal isOpen={isEditModalOpen} product={product} onClose={closeEditModal} refetch={fetch} />
            <AddBundleModal
                isOpen={isAddBundleModalOpen}
                product={product}
                onClose={() => setIsAddBundleModalOpen(false)}
                refetch={fetch}
            />
        </Flex>
    )
}
