import { Tabs, Typography } from 'antd'

import { BatchSchoolsTab } from './tabs/BatchSchoolsTab'
import { BatchUserInviteTab } from './tabs/BatchUserInviteTab'

const BatchDashboardPage = () => {
    return (
        <>
            <Typography.Title>Batch Scripts</Typography.Title>
            <Tabs style={{ padding: '20px' }}>
                <Tabs.TabPane tab="School Setup" key="schools">
                    <BatchSchoolsTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab="User Invites" key="user-invites">
                    <BatchUserInviteTab />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default BatchDashboardPage
