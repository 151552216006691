import { httpClient } from '@connectors/HttpClient'
import type { Settings } from '../types'

export const SettingsService = {
    list: async (): Promise<Settings[]> => {
        const response = await httpClient.axios.get(`/admin/settings`)
        return response.data
    },
    update: async (key: string, body: Partial<Settings>): Promise<Settings> => {
        const response = await httpClient.axios.put(`/admin/settings/${key}`, body)
        return response.data
    },
    flushallCache: async () => {
        const response = await httpClient.axios.post(`/admin/settings/flushall-cache`)
        return response.data
    }
}
