import React, { useEffect, useState } from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'

import { type BundleUpdateParams, BundleService } from '@services/BundleService'

import { type BundleFieldType, BundleForm } from '../components/BundleForm'
import type { Bundle } from '../../../../types'

type ModalProps = {
    selectedId: string | null
    onClose: () => void
    onSuccess: () => void
}

export const EditBundleModal: React.FC<ModalProps> = ({ selectedId, onClose, onSuccess }) => {
    const [data, setData] = useState<Bundle>()
    const { showNotification } = useNotifications()

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await BundleService.get(selectedId!)
                setData(results)
            } catch (err: any) {
                showNotification({ title: 'Could not retrieve bundle', type: 'error', description: err.toString() })
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<BundleFieldType>['onFinish'] = async values => {
        const body: BundleUpdateParams = {
            name: values.name,
            description: values.description,
            order: values.order
        }

        if (values.meta?.links) {
            body.meta = { links: values.meta.links }
        }

        try {
            await BundleService.update(selectedId!, body)
            showNotification({ title: 'Bundle updated successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Bundle update failed', type: 'error', description: err.toString() })
        }
    }

    const isOpen = !!selectedId

    if (!data) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose} width="700px">
            <BundleForm title="Edit Bundle" initialValues={data} onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
