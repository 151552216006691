import React, { useState } from 'react'
import { Button, Form, type FormProps, Input, Modal, Space, type NotificationArgsProps, notification } from 'antd'

import { type ProductParams, ProductService } from '@services/ProductService'

import type { Product } from '../../types'

type ModalProps = {
    product: Product
    isOpen: boolean
    onClose: () => void
    refetch: () => Promise<void>
}

type FieldType = {
    name?: string
    label?: string
    key?: string
    description?: string
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

export const EditProductModal: React.FC<ModalProps> = ({ isOpen, product, onClose, refetch }) => {
    const [api, contextHolder] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: ProductParams = {
            name: values.name!,
            label: values.label!,
            key: values.key!,
            description: values.description
        }

        try {
            setIsLoading(true)
            await ProductService.update(product.id!, body)
            openNotification('top', 'success', 'Product updated!')
            await refetch()
            onClose()
            form.resetFields()
        } catch (err: any) {
            openNotification('top', 'error', 'Product update failed!', err.toString())
        } finally {
            setIsLoading(false)
        }
    }

    if (!product) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {contextHolder}
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <h3>Edit Product</h3>
                <Form.Item label="Product ID">
                    <span>{product.id}</span>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    initialValue={product.name}
                    rules={[{ required: true, message: 'Please enter an internal product name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Label"
                    name="label"
                    initialValue={product.label}
                    rules={[{ required: true, message: 'Please enter a product label' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Key"
                    name="key"
                    initialValue={product.key}
                    rules={[{ required: true, message: 'Please enter a product key' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
