import { useEffect, useState } from 'react'
import { Button, Typography, Table, Space, Modal, Flex, notification } from 'antd'

import { SettingsService } from '@services/SettingsService'
import type { Settings } from '../../types'
import { EditSettingsModal } from '../components/EditSettingsModal'

export const SettingsDashboardPage = () => {
    const [settings, setSettings] = useState<Settings[]>([])
    const [selectedSetting, setSelectedSetting] = useState<Settings | null>(null)
    const [api, contextHolder] = notification.useNotification()
    const fetch = async () => {
        try {
            const results = await SettingsService.list()
            setSettings(results)
        } catch (err) {
            console.error('Unable to retrieve reports info')
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: Settings) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => {
                            setSelectedSetting(record)
                        }}
                    >
                        Edit
                    </Button>
                </Space>
            )
        }
    ]

    const flushallCache = async () => {
        Modal.confirm({
            title: 'Confirm Cache Flush',
            content: 'Are you sure you want to flush all cache?',
            onOk: async () => {
                try {
                    await SettingsService.flushallCache()
                    api.success({
                        message: 'Cache flushed',
                        description: 'All cache has been flushed',
                        placement: 'bottomRight'
                    })
                } catch (error) {
                    api.error({
                        message: 'Error',
                        description: 'Failed to flush cache',
                        placement: 'bottomRight'
                    })
                }
            },
            onCancel() {}
        })
    }

    return (
        <Flex vertical gap={40}>
            {contextHolder}
            <Typography.Title>Settings</Typography.Title>
            <Table columns={columns} dataSource={settings} rowKey="key" pagination={false} />
            <EditSettingsModal
                isOpen={!!selectedSetting}
                onClose={() => setSelectedSetting(null)}
                settings={selectedSetting || undefined}
                refetch={fetch}
            />
            <Button onClick={flushallCache} style={{ width: '20%' }} type="primary">
                Flush all cache
            </Button>
        </Flex>
    )
}
