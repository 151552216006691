import React from 'react'
import { type FormProps, Modal } from 'antd'

import { useNotifications } from '@store/notifications'
import { type BundleCreateParams, BundleService } from '@services/BundleService'

import { type BundleFieldType, BundleForm } from '../components/BundleForm'

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

export const CreateBundleModal: React.FC<ModalProps> = ({ isOpen, onClose, onSuccess }) => {
    const { showNotification } = useNotifications()

    const onFinish: FormProps<BundleFieldType>['onFinish'] = async values => {
        const body: BundleCreateParams = {
            name: values.name,
            description: values.description,
            order: values.order
        }

        if (values.meta?.links) {
            body.meta = { links: values.meta.links }
        }

        try {
            await BundleService.create(body)
            showNotification({ title: 'Bundle created successfully' })
            onSuccess()
            onClose()
        } catch (err: any) {
            showNotification({ title: 'Bundle created failed', type: 'error', description: err.toString() })
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            <BundleForm title="Create New Bundle" onFinish={onFinish} onCancel={onClose} />
        </Modal>
    )
}
