import { Alert, Button, Space, Table, Tag, Upload } from 'antd'
import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Check, CheckCircle, X } from '@phosphor-icons/react'

import { ProductService } from '@services/ProductService'
import { SchoolService } from '@services/SchoolService'
import { UserService } from '@services/UserService'

import { useNotifications } from '@store/notifications'
import { useConfirmation } from '@store/confirmation'

import type { Product, SubscriptionStatus, TermType } from '../../../../types'
import { PromiseThrottle } from '../utils/PromiseThrottle'

const AVAILABLE_PRODUCTS = [1, 2, 3]
const PRODUCT_MAP = {
    1: ['MNP-NZ-P1'],
    2: ['MNP-NZ-P2'],
    3: ['MNP-NZ-P3']
}
type ProductMap = { [key: string]: Product[] }

type StatusTypes = 'DRAFT' | 'INVALID' | 'COMPLETE'

type SubscriptionCsvRow = [schoolName: string, adminName: string, seats: string, adminEmail: string, products: string]
type Subscription = {
    schoolName: string
    adminName: string
    seats: number
    adminEmail: string
    products: Product[]
    isValid: boolean
    status: StatusTypes
}

const validateEmail = (email: string) => {
    const regexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return email.match(regexp)
}
const validateProduct = (products: number[]) => {
    if (products.length === 0) {
        return false
    }

    const valid = products.reduce((isValid, product) => {
        return isValid && AVAILABLE_PRODUCTS.includes(product)
    }, true)

    return valid
}

const validateSeats = (seats: string) => {
    // only checking that the "seats" string is a whole number
    return /^-?\d+$/.test(seats)
}

const formatProduct = (name: string) => {
    const formatted = name.trim().toLocaleLowerCase().replace('phase', '')
    const num = parseInt(formatted, 10)

    return num
}

export const BatchSchoolsTab = () => {
    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const [subscriptions, setSubscriptions] = useState<Subscription[]>()
    const [areSubscriptionsValid, setAreSubscriptionsValid] = useState<boolean>(false)
    const [availableProducts, setAvailableProducts] = useState<ProductMap>()
    const [statuses, setStatuses] = useState<{ [key: string]: boolean }>({})
    const [messages, setMessages] = useState<string[]>([])

    useEffect(() => {
        const fetchProducts = async () => {
            const products = await ProductService.list()

            const formatted = Object.entries(PRODUCT_MAP).reduce((collector: ProductMap, entry) => {
                const [phase, productKeys] = entry

                const mapped = productKeys.map(key => {
                    const found = products.find(product => product.key === key)

                    if (!found) {
                        throw new Error('Not found')
                    }
                    return found
                })

                collector[phase] = mapped

                return collector
            }, {})

            setAvailableProducts(formatted)
        }

        fetchProducts()
    }, [])

    const beforeUpload = (file: File) => {
        // supposed to be File according to docs but TypeScript doesn't like it
        Papa.parse(file as any, {
            complete: results => {
                let validityTracker = true
                const data = results.data as SubscriptionCsvRow[]
                const errorMessages: string[] = []

                // Format and validate data
                const mapped = data.map((row: SubscriptionCsvRow) => {
                    const [schoolName, adminName, seats, adminEmail, products] = row
                    const formattedProducts = products
                        .trim()
                        .split(',')
                        .filter(product => product !== '')
                        .map(product => formatProduct(product))

                    const isValidProduct = validateProduct(formattedProducts)

                    const mappedProducts = formattedProducts.flatMap(key => availableProducts![key])

                    // @ts-ignore filter out duplicate products - this is the easiest way
                    const uniqueProducts = [...new Set(mappedProducts)]

                    const isValidEmail = validateEmail(adminEmail.trim().toLowerCase())

                    const isValidSeat = validateSeats(seats.trim())

                    const isValid = !!(isValidProduct && isValidEmail && isValidSeat)

                    validityTracker = !!(validityTracker && isValid)

                    const status = isValid ? 'DRAFT' : 'INVALID'

                    if (!isValidSeat) {
                        errorMessages.push(`${schoolName.trim()} has an invalid seat number :: ${seats}`)
                    }

                    if (!isValidEmail) {
                        errorMessages.push(`${schoolName.trim()} has an invalid email address :: ${adminEmail}`)
                    }

                    if (!isValidProduct) {
                        errorMessages.push(`${schoolName.trim()} has an invalid product assignment :: ${products}`)
                    }

                    return {
                        status: status as StatusTypes,
                        schoolName: schoolName.trim(),
                        adminName: adminName.trim(),
                        seats: parseInt(seats, 10),
                        adminEmail: adminEmail.trim().toLowerCase(), //  `elton+${i}@fuzzyed.com`, //
                        products: uniqueProducts,
                        isValid
                    }
                })

                setAreSubscriptionsValid(validityTracker)

                setSubscriptions(mapped)

                setMessages(errorMessages)
            }
        })
        return false
    }

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value: StatusTypes, _record: Subscription, i: number) => (
                <>
                    {value === 'DRAFT' && <Check />}
                    {(value === 'INVALID' || (typeof statuses[i] === 'boolean' && statuses[i] === false)) && (
                        <X color="red" />
                    )}
                    {statuses[i] && <CheckCircle />}
                </>
            )
        },
        {
            title: 'School Name',
            dataIndex: 'schoolName',
            key: 'schoolName'
        },
        {
            title: 'Admin Name',
            dataIndex: 'adminName',
            key: 'adminName'
        },
        {
            title: 'Seats',
            dataIndex: 'seats',
            key: 'seats'
        },
        {
            title: 'Admin Email',
            dataIndex: 'adminEmail',
            key: 'adminEmail'
        },
        {
            title: 'Products',
            dataIndex: 'products',
            key: 'products',
            render: (products: Product[], record: Subscription) => (
                <>{record.isValid && products.map(product => <Tag key={product.key}>{product.key}</Tag>)}</>
            )
        }
    ]

    const processBatch = async () => {
        if (
            await confirm({
                title: 'Do you want to process these schools?'
            })
        ) {
            if (!subscriptions) {
                throw new Error('No subscriptions')
            }

            const promiseThrottle = new PromiseThrottle({ limit: 2 })

            subscriptions.forEach(async (subscription, i) => {
                promiseThrottle.add(async () => {
                    try {
                        const body = {
                            name: subscription.schoolName,
                            country: 'New Zealand',
                            email: subscription.adminEmail
                        }

                        // Add the school
                        const school = await SchoolService.create(body)

                        const startDate = new Date('Jan 19, 2025')
                        const endDate = new Date('Jan 19, 2026')

                        const formattedValues = {
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            schoolId: school.id,
                            productIds: subscription.products.map(product => product.id),
                            status: 'ENDING' as SubscriptionStatus,
                            seatCount: subscription.seats,
                            term: 'YEARLY' as TermType
                        }

                        // Add the subscription
                        const subscriptionResult = await SchoolService.addSubscription(school.id, formattedValues)

                        // Get the subscription (we want the schoolSubscription id)
                        const fetchedSubscription = await SchoolService.getSubscription(
                            school.id,
                            subscriptionResult.id
                        )

                        const addUserBody = {
                            email: subscription.adminEmail,
                            role: 'ADMIN'
                        }

                        // Create the user and add them to the school
                        const userResult = await SchoolService.addUser(school.id, addUserBody, { shouldEmail: false })

                        // fetch the user (we want the schoolUser id)
                        const fetchedUser = await UserService.get(userResult.id)

                        if (!fetchedUser) {
                            statuses[i] = false
                            setStatuses(statuses)
                            return 'FAILED'
                        }

                        const foundSchoolUser = fetchedUser.schoolUsers.find(
                            schoolUser => schoolUser.schoolId === school.id
                        )

                        if (!fetchedSubscription.schoolSubscription || !foundSchoolUser) {
                            statuses[i] = false
                            setStatuses(statuses)
                            return 'FAILED'
                        }

                        const assignUserBody = {
                            add: { [fetchedSubscription.schoolSubscription.id]: [foundSchoolUser.id] },
                            remove: {}
                        }
                        await SchoolService.assignUsers(school.id, assignUserBody)

                        statuses[i] = true
                        setStatuses(statuses)
                        return 'COMPLETE'
                    } catch (err) {
                        statuses[i] = false
                        setStatuses(statuses)
                        return 'FAILED'
                    }
                })
            })

            await promiseThrottle.start()

            showNotification({ title: 'Processing complete' })
        }
    }

    if (!availableProducts) {
        return null
    }

    return (
        <>
            {(!subscriptions || subscriptions.length === 0) && (
                <>
                    <p>Must have a clean CSV prior to uploading</p>
                    <Upload accept=".csv" showUploadList={false} beforeUpload={beforeUpload}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </>
            )}
            {subscriptions && subscriptions.length > 0 && (
                <Space direction="vertical">
                    <Button onClick={() => setSubscriptions([])}>Clear</Button>
                    {messages && messages.map(message => <Alert key={message} message={message} type="error" />)}

                    <Table
                        dataSource={subscriptions}
                        columns={columns}
                        pagination={false}
                        rowClassName={record => (!record.isValid ? 'error-row' : '')}
                    />
                    <Button onClick={processBatch} disabled={!areSubscriptionsValid}>
                        Run
                    </Button>
                </Space>
            )}
        </>
    )
}
