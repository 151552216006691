/* eslint-disable react/jsx-props-no-spreading */
import { Button, Divider, Form, Input, Space } from 'antd'
import { MinusCircle, PlusSquare } from '@phosphor-icons/react'

import type { WebLink } from '../../../../types'

export type BundleFieldType = {
    name: string
    description: string
    order: number
    meta?: {
        links: WebLink[]
    }
}

type BundleFormProps = {
    title: string
    initialValues?: BundleFieldType
    onFinish: (values: BundleFieldType) => void
    onCancel: () => void
}

const defaultFormItemLayout = {
    labelCol: {
        xs: { span: 6 }
    },
    wrapperCol: {
        xs: { span: 12 }
    }
}

export const BundleForm = ({ title, initialValues, onFinish, onCancel }: BundleFormProps) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} {...defaultFormItemLayout} disabled={false} onFinish={onFinish}>
            <h3>{title}</h3>
            <Form.Item
                label="Name"
                name="name"
                initialValue={initialValues?.name}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                {...defaultFormItemLayout}
                label="Description"
                name="description"
                initialValue={initialValues?.description}
                rules={[{ required: true, message: 'Please enter a description' }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Divider dashed>Links</Divider>

            <Form.Item
                label="Order"
                name="order"
                initialValue={initialValues?.order}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.List name={['meta', 'links']} initialValue={initialValues?.meta?.links}>
                {(fields, { add, remove }) => (
                    <div style={{ textAlign: 'center' }}>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Form.Item
                                    {...restField}
                                    label="Name"
                                    name={[name, 'name']}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label="Url"
                                    name={[name, 'url']}
                                    rules={[{ required: true, type: 'url' }]}
                                >
                                    <Input placeholder="Url" />
                                </Form.Item>
                                <Button
                                    danger
                                    className="dynamic-delete-button"
                                    onClick={() => remove(name)}
                                    icon={<MinusCircle />}
                                >
                                    Remove Above Field
                                </Button>
                                <Divider />
                            </>
                        ))}
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusSquare />}
                            style={{ width: '150px' }}
                        >
                            Add link
                        </Button>
                    </div>
                )}
            </Form.List>
            <Divider />

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

BundleForm.defaultProps = {
    initialValues: undefined
}
