import { httpClient } from '@connectors/HttpClient'

import { Subscription, type User } from '../types'

export type UserParams = Partial<User>

export const MyUserService = {
    my: async (): Promise<User> => {
        const response = await httpClient.axios.get('/my/user')
        return response.data
    }
}

export type AddSubscriptionParams = Partial<Subscription> & {
    productIds: string[]
}

export const UserService = {
    search: async ({
        offset,
        limit,
        searchValue = '',
        sortBy = 'createdAt',
        sortOrder = 'desc'
    }: {
        offset: number
        limit: number
        searchValue?: string
        sortBy?: string
        sortOrder?: string
    }): Promise<{
        totalCount: number
        users: User[]
    }> => {
        const response = await httpClient.axios.get('/admin/users', {
            params: {
                offset,
                limit,
                searchValue,
                sortBy,
                sortOrder
            }
        })
        return response.data
    },
    get: async (id: string): Promise<User> => {
        const response = await httpClient.axios.get(`/admin/users/${id}`)
        return response.data
    },
    update: async (id: string, params: UserParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/users/${id}`, params)
        return response.data
    },
    addSubscription: async (userId: string, params: AddSubscriptionParams): Promise<any> => {
        const response = await httpClient.axios.post(`/admin/users/${userId}/subscriptions`, params)
        return response.data
    },
    updateSubscription: async (userId: string, subscriptionId: string, params: AddSubscriptionParams): Promise<any> => {
        const response = await httpClient.axios.put(`/admin/users/${userId}/subscriptions/${subscriptionId}`, params)
        return response.data
    },
    deleteSubscription: async (userId: string, subscriptionId: string): Promise<any> => {
        const response = await httpClient.axios.delete(`/admin/users/${userId}/subscriptions/${subscriptionId}`)
        return response.data
    },
    resendInvite: async (userId: string, schoolId?: string): Promise<any> => {
        const params = new URLSearchParams()
        if (schoolId) {
            params.append('schoolId', schoolId)
        }

        const response = await httpClient.axios.put(`/admin/users/${userId}/resend-invite?${params.toString()}`)
        return response.data
    }
}
