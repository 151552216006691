import React, { useState } from 'react'
import { Button, Form, type FormProps, Input, Modal, Space, type NotificationArgsProps, notification } from 'antd'

import { SettingsService } from '@services/SettingsService'

import { Settings } from '../../types'

type ModalProps = {
    // eslint-disable-next-line react/require-default-props
    settings?: Settings
    isOpen: boolean
    onClose: () => void
    refetch: () => Promise<void>
}

type FieldType = {
    key?: string
    value?: string
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

export const EditSettingsModal: React.FC<ModalProps> = ({ settings, isOpen, onClose, refetch }) => {
    const [api, contextHolder] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    if (!settings) {
        return null
    }

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: any = {
            value: values.value!
        }

        try {
            setIsLoading(true)
            await SettingsService.update(settings.key!, body)
            openNotification('top', 'success', 'Setting updated!')
            await refetch()
            onClose()
            form.resetFields()
        } catch (err: any) {
            openNotification('top', 'error', 'Setting update failed!', err.toString())
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {contextHolder}
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <h3>Edit Settings {settings.key}</h3>

                <Form.Item
                    label="Value"
                    name="value"
                    initialValue={settings.value}
                    rules={[{ required: true, message: 'Please enter a value' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
