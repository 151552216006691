import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'

import {
    Bridge,
    Buildings,
    ChartLine,
    Cylinder,
    Gear,
    GraduationCap,
    House,
    Package,
    PaperPlaneTilt,
    SignOut,
    User
} from '@phosphor-icons/react'
import { auth } from '@connectors/Firebase'

export const NavigationMenu = () => {
    const navigate = useNavigate()

    const onClick = (e: any) => {
        navigate(e.key)
    }

    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
                {
                    key: '/',
                    icon: <House />,
                    label: 'Dashboard',
                    onClick
                },
                {
                    key: '/users',
                    icon: <User />,
                    label: 'Users',
                    onClick
                },
                {
                    key: '/schools',
                    icon: <Buildings />,
                    label: 'Schools',
                    onClick
                },
                {
                    key: '/publishers',
                    icon: <Buildings />,
                    label: 'Publishers',
                    onClick
                },
                {
                    key: '/products',
                    icon: <Package />,
                    label: 'Products',
                    onClick
                },
                {
                    key: '/reports',
                    icon: <ChartLine />,
                    label: 'Reports',
                    onClick
                },
                {
                    key: '/curriculums',
                    icon: <Cylinder />,
                    label: 'Curriculum',
                    onClick
                },
                {
                    key: '/year-levels',
                    icon: <GraduationCap />,
                    label: 'Year Level',
                    onClick
                },
                {
                    key: '/curriculum-builder',
                    icon: <Bridge />,
                    label: 'Curriculum Builder',
                    onClick
                },
                {
                    key: '/bundles',
                    icon: <PaperPlaneTilt />,
                    label: 'Bundles',
                    onClick
                },
                {
                    key: '/settings',
                    icon: <Gear />,
                    label: 'Settings',
                    onClick
                },
                {
                    key: '#logout',
                    icon: <SignOut />,
                    label: 'Sign Out',
                    onClick: async () => {
                        await auth.signOut()
                    }
                }
            ]}
        />
    )
}
