import { useEffect, useState } from 'react'
import { Button, Flex, Select, Spin, Table, type TableProps } from 'antd'
import { Trash } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

import { useConfirmation } from '@store/confirmation'
import { useNotifications } from '@store/notifications'
import type { School, SchoolUser } from '../../../types'
import { type AssignedSchoolUers, SchoolService } from '../../../services/SchoolService'

export const UserTable = ({ school, refetch }: { school: School; refetch: () => Promise<void> }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [formAssignedSeats, setFormAssignedSeats] = useState<AssignedSchoolUers>({
        add: {},
        remove: {}
    })

    useEffect(() => {
        setFormAssignedSeats({
            add: {},
            remove: {}
        })
    }, [school])

    const navigate = useNavigate()

    const confirm = useConfirmation()
    const { showNotification } = useNotifications()

    const handleSave = async () => {
        setIsLoading(true)
        if (
            await confirm({
                title: 'Are you sure you want to assign/unassign subscriptions?',
                confirmText: 'Save'
            })
        ) {
            try {
                await SchoolService.assignUsers(school.id, formAssignedSeats)
                showNotification({ title: 'Subscriptions assigned' })
                await refetch()
            } catch (err) {
                showNotification({ title: 'Error assigning subscriptions', type: 'error' })
                setIsLoading(false)
                await refetch()
            }
        }
        setIsLoading(false)
    }

    const handleRemove = async (userId: string) => {
        if (
            await confirm({
                title: 'Are you sure you want to remove this user?',
                confirmText: 'Remove'
            })
        ) {
            try {
                await SchoolService.removeUser(school.id, userId)
                showNotification({ title: 'User removed' })
                await refetch()
            } catch (err) {
                showNotification({ title: 'Error removing user', type: 'error' })
            }
        }
    }

    const columns: TableProps<SchoolUser>['columns'] = [
        {
            title: 'ID',
            key: 'id',
            render: (_, { user }) => <>{user.id}</>
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, { user }) => <>{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}</>
        },
        {
            title: 'Email',
            key: 'email',
            render: (_, { user }) => <>{user.email}</>
        },
        {
            title: 'School Role',
            dataIndex: 'role'
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, { user }) => <>{user.status}</>
        },
        {
            title: 'Subscriptions',
            dataIndex: 'subscriptions',
            render: (_, { id: schoolUserId, schoolSubscriptionSeats }) => {
                const initialSchoolSubscriptions = school.schoolSubscriptions.filter(ss =>
                    schoolSubscriptionSeats?.some(seat => seat.schoolSubscriptionId === ss.id)
                )
                return (
                    <Select
                        style={{ minWidth: '300px' }}
                        mode="multiple"
                        options={school.schoolSubscriptions.map(s => ({
                            label: s.subscription.subscriptionProducts.map(sp => sp.product.name).join(', '),
                            value: s.id,
                            color: 'green'
                        }))}
                        defaultValue={initialSchoolSubscriptions.map(ss => ss.id) || []}
                        onChange={newSubIds => {
                            const existingSubIds = initialSchoolSubscriptions.map(ss => ss.id)

                            // get diff between value and initialSchoolSubscriptions
                            const addIds = newSubIds.filter(v => !existingSubIds.includes(v))
                            const removedIds = existingSubIds.filter(id => !newSubIds.includes(id))

                            setFormAssignedSeats(prev => {
                                const newAdd = { ...prev.add }
                                const newRemove = { ...prev.remove }

                                // Cleanup, remove everything from this user
                                Object.keys(newAdd).forEach(subscriptionId => {
                                    newAdd[subscriptionId] = newAdd[subscriptionId].filter(id => id !== schoolUserId)
                                })
                                Object.keys(newRemove).forEach(subscriptionId => {
                                    newRemove[subscriptionId] = newRemove[subscriptionId].filter(
                                        id => id !== schoolUserId
                                    )
                                })

                                // Add new values
                                addIds.forEach(subscriptionId => {
                                    newAdd[subscriptionId] = [...(newAdd[subscriptionId] || []), schoolUserId]
                                })
                                removedIds.forEach(subscriptionId => {
                                    newRemove[subscriptionId] = [...(newRemove[subscriptionId] || []), schoolUserId]
                                })

                                return {
                                    add: newAdd,
                                    remove: newRemove
                                }
                            })
                        }}
                    />
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, { user }) => (
                <Flex gap="middle" align="center">
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(`/users/${user.id}`)
                        }}
                    >
                        View
                    </Button>
                    <Button
                        type="link"
                        onClick={async () => {
                            if (
                                await confirm({
                                    title: 'Do you want to resend the invite?'
                                })
                            ) {
                                try {
                                    await SchoolService.resendInvite(school.id, user.id)
                                    showNotification({ title: 'Invite sent!' })
                                } catch (err) {
                                    showNotification({ title: 'Failed to resend invite', type: 'error' })
                                }
                            }
                        }}
                    >
                        Resend Invite
                    </Button>
                    <Trash size={20} color="red" onClick={() => handleRemove(user.id)} cursor="pointer" />
                </Flex>
            )
        }
    ]

    if (!school) return <Spin />

    return (
        <Flex vertical gap={20}>
            <Button
                type="default"
                onClick={handleSave}
                loading={isLoading}
                style={{ width: 'fit-content', alignSelf: 'flex-end' }}
                disabled={
                    Object.keys(formAssignedSeats.add).length === 0 &&
                    Object.keys(formAssignedSeats.remove).length === 0
                }
            >
                Save assigned subscriptions
            </Button>
            <Table columns={columns} dataSource={school.schoolUsers || []} rowKey="id" pagination={{ pageSize: 50 }} />
        </Flex>
    )
}
